import React, { useContext, useState } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Typography,
    Tooltip,
    colors,
    Icon,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    withTheme,
    Divider,
    Button,
    Chip,
    Paper
} from '@material-ui/core';

import { loc, locDate } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

import bulkHelper from 'helpers/bulkHelper';

import { _bulk, _commodity, _user } from 'std';

import {
    isEXPRegion,
    getCustomerName,
    formatAsCurrency,
    uppercaseFirstLetter,
    truncateBagtag,
    getPlural,
    getCustomerFirstNameAndLastInitial
} from 'utils/misc';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import HttpContext from 'utils/contexts/HttpContext';
import { openWindow } from 'helpers/windowHelper';

import Bagtags from './Bagtags';
import { isSystemAdmin } from 'std/helpers/user';

function ProcessingSummary(props) {
    const {
        bulks,
        customer,
        pickup,
        adminView,
        commoditiesAvailable,
        history,
        auth,
        rates,
        onViewCustomerReport,
        onViewComplaintDialog,
        style,
        payloadIssuesTxt,
        theme,
        rolePermissions
    } = props;

    const { lang } = useContext(LocalizationContext);
    const http = useContext(HttpContext);
    const beverageBulks = _.filter(bulks, bulk => _bulk.isBeverage(bulk));
    const firstBulk = _.first(beverageBulks) || _.first(bulks);
    const manuallyCountedBulks = _.filter(
        bulks,
        b => bulkHelper.isCommodityManual(b, commoditiesAvailable) && b.dateCounted
    );
    const nonManuallyCountedBulks = _.filter(
        bulks,
        b => !bulkHelper.isCommodityManual(b, commoditiesAvailable) && b.dateCounted
    );
    const lastManualCountedBulk = _.last(manuallyCountedBulks);
    const lastCount = _.last(_.get(lastManualCountedBulk, 'counts', []));
    const lastCounter = _.get(lastCount, 'operator');

    const sessions = _.get(firstBulk, 'countingSessions', []);

    let machineCounter = '';
    const firstSession = _.first(sessions);
    const tomraVariables = _.get(firstSession, 'tomraVariables');
    if (tomraVariables) {
        const machine = tomraVariables.rvmName;
        const serialNumber = tomraVariables.rvmSerialNo;
        machineCounter = `${machine.replace(' ', '')}-${serialNumber}`;
    }

    const allBagTags = bulks.flatMap(b =>
        isEXPRegion() && !_.isNil(b.expectedBagtags) && !_.isEmpty(b.expectedBagtags)
            ? b.expectedBagtags.map(bt => ({ bagtag: bt, dateCounted: b.dateCounted }))
            : { bagtag: b.label, dateCounted: b.dateCounted }
    );

    const tableCellStyles = { padding: 0, color: theme.palette.text.secondary, borderBottom: 'none' };

    const timezone = _.get(firstBulk, 'pickup.location.timezone', process.env.REACT_APP_REGION_TIMEZONE);

    const hasComplaints = _.get(firstBulk, 'customerIssues.customerHasIssue', false);

    const payloadIssues = _.get(firstBulk, 'payloadIssues.issues', null);
    const payloadIssuesImages = _.get(firstBulk, 'payloadIssues.images', []);
    const hasIssues = !_.isEmpty(payloadIssues) && !_.isNil(payloadIssuesImages);
    const customerPaymentFees = _.filter(_.get(firstBulk, 'customFees', []), fee =>
        _.get(fee, 'description', '').includes('paid with card ending in')
    );

    const handleViewBulk = async (externalWindow, bulk) => {
        let res;
        if (!_.isNil(pickup)) {
            res = await http.getJSON(`/bulk/?pickup_id=${_.get(pickup, '_id')}`);
        }
        if (_.isNil(pickup) || !res.ok || !_.get(res.data, 'bulk._id', null)) {
            if (!_.isNil(bulk)) {
                const URL = `/operators/${auth._id}/bulks/${bulk._id}`;
                openWindow(history, externalWindow, URL);
            }
            return;
        }

        const URL = `/operators/${auth._id}/bulks/${res.data.bulk._id}`;
        openWindow(history, externalWindow, URL);
    };

    //const commodityAmount = _.reduce(bulks, (sum, b) => sum + _.get(b, 'commodityAmount', 0), 0);

    // Hide component if beverage bulks aren't closed
    for (let beverageBulk of beverageBulks) {
        if (!beverageBulk.dateCompleted) {
            return <></>;
        }
    }

    return (
        <>
            <Paper
                style={{
                    padding: theme.spacing.unit * 2,
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: theme.palette.background.default,
                    ...style
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
                    <Typography variant="h6" style={{ marginRight: theme.spacing.unit * 2, fontSize: '1rem' }}>
                        {loc('dashboardHistory59', lang)}
                    </Typography>
                </div>
                {hasComplaints && (
                    <Typography>
                        <span style={{ color: colors.red[500] }}>{uppercaseFirstLetter(loc('complaints', lang))}:</span>
                        <span
                            onClick={adminView && onViewComplaintDialog(firstBulk, null)}
                            style={{
                                color: bulkHelper.hasUnresolvedCustomerIssue(firstBulk) && colors.red[500],
                                cursor: adminView && 'pointer'
                            }}
                        >
                            <span
                                style={{
                                    background: bulkHelper.hasUnresolvedCustomerIssue(firstBulk)
                                        ? colors.red[500]
                                        : colors.blue[100],
                                    color: theme.palette.getContrastText(
                                        bulkHelper.hasUnresolvedCustomerIssue(firstBulk)
                                            ? colors.red[500]
                                            : colors.blue[100]
                                    ),
                                    fontSize: theme.typography.fontSize * 0.875,
                                    marginRight: theme.spacing.unit,
                                    marginLeft: theme.spacing.unit,
                                    padding: '2px 4px',
                                    borderRadius: 4
                                }}
                            >
                                {bulkHelper.hasUnresolvedCustomerIssue(firstBulk) ? 'UNRESOLVED' : 'RESOLVED'}
                            </span>
                            <span
                                style={{
                                    textDecoration: 'underline',
                                    color: bulkHelper.hasUnresolvedCustomerIssue(firstBulk)
                                        ? colors.red[500]
                                        : theme.palette.text.secondary
                                }}
                            >
                                {_.get(firstBulk, 'customerIssues.issueDescription', '')}
                            </span>
                        </span>
                    </Typography>
                )}
                <Typography style={{ marginTop: hasComplaints ? 5 : 0 }} color="textSecondary">
                    {!_bulk.isAdjustment(firstBulk) ? (
                        <>
                            {loc('dashboardHistory72', lang)}{' '}
                            <b>
                                {locDate(firstBulk.dateCompleted, 'MMM Do, YYYY', lang, timezone)} {loc('at', lang)}{' '}
                                {locDate(firstBulk.dateCompleted, 'h:mm a', lang, timezone)}.
                            </b>{' '}
                        </>
                    ) : (
                        <>
                            {loc('dashboardHistory73', lang, {
                                adjustmentReason: _.get(firstBulk, 'adjustmentReason', '').toLowerCase()
                            })}
                        </>
                    )}
                </Typography>
                {hasIssues && (
                    <Typography style={{ marginTop: 5 }} color="textSecondary">
                        <span style={{ color: colors.red[500] }}>
                            {loc('dashboardHistory21', lang)}: {payloadIssuesTxt.map(issue => issue.name).join(', ')}
                            {', '}
                            <span
                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                onClick={onViewCustomerReport(firstBulk, null)}
                            >
                                {loc('dashboardHistory22', lang)}
                            </span>
                        </span>
                    </Typography>
                )}
                <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                    <TableBody>
                        {customerPaymentFees.map((fee, i) => (
                            <TableRow key={i} style={{ height: 20 }}>
                                <TableCell style={tableCellStyles}>{loc('paymentReceived', lang)}</TableCell>
                                <TableCell style={{ ...tableCellStyles, textAlign: 'right', fontWeight: 'bold' }}>
                                    {formatAsCurrency(fee.amount * -1)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {manuallyCountedBulks.map(b => {
                    if (_.isEmpty(_.get(b, 'counts', []))) return null;
                    let uom = _.get(b, 'commodityUOM', _bulk.getBulkUnitOfMeasurement(b));
                    if (b.commodityAmount > 1) {
                        uom += 's';
                    }
                    const isManual = bulkHelper.isCommodityManual(b, commoditiesAvailable);

                    let commodityType = b.skuType;
                    let commodityForBulk = _.find(commoditiesAvailable, c => c.skuType === b.skuType);
                    if (!_.isNil(commodityForBulk) && !_.isNil(commodityForBulk.payloadInputName)) {
                        commodityType = _.get(commodityForBulk, `payloadInputName.${lang}`, b.skuType);
                    }
                    const commodityIcon = _.get(commodityForBulk, 'iconName');

                    let subPayloads = _(_.get(b, 'commoditiesProcessedBreakdown', []))
                        .filter({ isSubCommodity: true })
                        .map(entry => {
                            return {
                                amount: _.get(entry, 'totalAmount', 0),
                                skuType: b.skuType,
                                subCommodity: _.get(entry, 'subCommodity')
                            };
                        })
                        .value();

                    const combinedPayloadItems = _commodity.getCombinedPayloadItemsForPickups(
                        [{ payload: {}, subPayloads }],
                        commoditiesAvailable
                    );
                    const groupedPayloadTotals = _commodity.groupPayloadItemCountsByUnit(combinedPayloadItems);

                    /*let counts = (
                        <TableRow style={{ height: 36 }}>
                            <TableCell
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    color: theme.palette.text.disabled
                                }}
                                colspan="2"
                            >
                                {isManual ? loc('dashboardHistory53', lang) : loc('dashboardHistory54', lang)}
                            </TableCell>
                        </TableRow>
                    );*/
                    let counts = <></>;
                    let totalCounts = 0;
                    if (b.dateCounted) {
                        if (_.isNil(b.expectedBagtags) || _.isEmpty(b.expectedBagtags)) {
                            const { totalCounts: totalCountsRet, counts: countsRet } = getGroupedCounts({
                                b,
                                rates,
                                commodityIcon,
                                tableCellStyles,
                                theme,
                                lang
                            });
                            totalCounts = totalCountsRet;
                            counts = countsRet;
                        } else {
                            const { totalCounts: totalCountsRet, counts: countsRet } = getGroupedCountsByBagtag({
                                b,
                                rates,
                                commodityIcon,
                                tableCellStyles,
                                theme,
                                lang
                            });
                            totalCounts = totalCountsRet;
                            counts = countsRet;
                        }
                    }

                    let driverTip = _.get(b, 'tip.adjustedAmount');
                    if (!_.isNil(driverTip)) driverTip *= 100;

                    return (
                        <div style={{ marginTop: 5 }} key={b._id}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography color="textSecondary">{uppercaseFirstLetter(commodityType)}</Typography>
                                {!b.dateCounted && (
                                    <Typography style={{ marginRight: theme.spacing.unit }} color="textSecondary">
                                        {loc('taxReceipts8', lang)}
                                    </Typography>
                                )}
                                {/*display total counts inline with commodity type for non manual */}
                                {b.dateCounted && !isManual && (
                                    <Typography style={{ marginRight: theme.spacing.unit }} color="textSecondary">
                                        {_.round(totalCounts, 2)}{' '}
                                        {_.get(b, 'skuType', '') === 'clothing' ? 'lbs' : loc('items', lang)}
                                    </Typography>
                                )}
                            </div>

                            {isManual && (
                                <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                                    <TableBody>
                                        {!_.isNil(b.dateCounted) && (
                                            <>
                                                {counts}
                                                {Object.keys(groupedPayloadTotals).map((unit, i) => (
                                                    <TableRow style={{ height: 20 }} key={i}>
                                                        <TableCell style={tableCellStyles}>
                                                            <span style={{ marginRight: theme.spacing.unit }}>
                                                                <MDIcon
                                                                    path={_.get(allIcons, commodityIcon)}
                                                                    size={0.6}
                                                                    color={theme.palette.text.secondary}
                                                                />
                                                            </span>
                                                            {uppercaseFirstLetter(
                                                                groupedPayloadTotals[unit] > 1 ? getPlural(unit) : unit
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                ...tableCellStyles,
                                                                textAlign: 'right'
                                                            }}
                                                        >
                                                            {groupedPayloadTotals[unit]}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                {/*(_.get(b, 'skuType', '') === 'beverage' ||
                                                        _.get(b, 'skuType', '') === 'collectionbins') &&
                                                        bulkHelper.isCompleted(b) && (
                                                            <TableRow key={`${b._id}-value`} style={{ height: 36 }}>
                                                                <TableCell
                                                                    style={{
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0
                                                                    }}
                                                                >
                                                                    {loc('impactReport14', lang)}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                        fontWeight: 'bold',
                                                                        textAlign: 'right'
                                                                    }}
                                                                >
                                                                    {_bulk.getCountsTotalItemCount(
                                                                        b,
                                                                        ignoredSkusForTotals
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                                    )*/}
                                                {/*(bulkHelper.getCustomerAmount(b) !== 0 ||
                                                        _.get(b, 'skuType', '') === 'beverage' ||
                                                        _.get(b, 'skuType', '') === 'collectionbins' ||
                                                        bulkHelper.isDonating(b)) && (
                                                        <TableRow key={`${b._id}-value`} style={{ height: 36 }}>
                                                            <TableCell
                                                                style={{
                                                                    paddingTop: 0,
                                                                    paddingBottom: 0
                                                                }}
                                                            >
                                                                {loc('value', lang)}
                                                                {driverTip && ' (Before Tip)'}
                                                            </TableCell>
                                                            {bulkHelper.isCompleted(b) ? (
                                                                <TableCell
                                                                    data-cy="customer-history-value-before-tip"
                                                                    style={{
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                        fontWeight: 'bold',
                                                                        textAlign: 'right'
                                                                    }}
                                                                >
                                                                    {formatAsCurrency(bulkHelper.getCustomerAmount(b))}
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell
                                                                    style={{
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                        color: colors.deepOrange[400],
                                                                        textAlign: 'right'
                                                                    }}
                                                                >
                                                                    TBD
                                                                </TableCell>
                                                            )}
                                                        </TableRow>
                                                                )*/}
                                                {driverTip && bulkHelper.isCompleted(b) && (
                                                    <>
                                                        <TableRow key={`${b._id}-value`} style={{ height: 20 }}>
                                                            <TableCell
                                                                style={{
                                                                    ...tableCellStyles,
                                                                    paddingTop: 0,
                                                                    paddingBottom: 0
                                                                }}
                                                            >
                                                                Driver Tip
                                                            </TableCell>
                                                            <TableCell
                                                                data-cy="customer-history-driver-tip"
                                                                style={{
                                                                    ...tableCellStyles,
                                                                    paddingTop: 0,
                                                                    paddingBottom: 0,
                                                                    fontWeight: 'bold',
                                                                    textAlign: 'right',
                                                                    color: colors.red[500]
                                                                }}
                                                            >
                                                                {formatAsCurrency(-driverTip, lang)}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key={`${b._id}-value`} style={{ height: 36 }}>
                                                            <TableCell
                                                                style={{
                                                                    ...tableCellStyles,
                                                                    paddingTop: 0,
                                                                    paddingBottom: 0
                                                                }}
                                                            >
                                                                Value (After Tip)
                                                            </TableCell>
                                                            <TableCell
                                                                data-cy="customer-history-value-after-tip"
                                                                style={{
                                                                    ...tableCellStyles,
                                                                    paddingTop: 0,
                                                                    paddingBottom: 0,
                                                                    fontWeight: 'bold',
                                                                    textAlign: 'right'
                                                                }}
                                                            >
                                                                {formatAsCurrency(
                                                                    bulkHelper.getCustomerAmount(b) - driverTip,
                                                                    lang
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {_.isEmpty(counts) && (
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        ...tableCellStyles,
                                                        paddingTop: 0,
                                                        paddingBottom: 0
                                                    }}
                                                >
                                                    {loc('dashboardHistory55', lang)}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            )}
                        </div>
                    );
                })}
                {!_.isEmpty(nonManuallyCountedBulks) && (
                    <>
                        <Typography color="textSecondary">{loc('other', lang)}</Typography>
                        <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                            <TableBody>
                                {nonManuallyCountedBulks.map(b => {
                                    let uom = _.get(b, 'commodityUOM', _bulk.getBulkUnitOfMeasurement(b));
                                    if (b.commodityAmount > 1) {
                                        uom += 's';
                                    }
                                    const isManual = bulkHelper.isCommodityManual(b, commoditiesAvailable);

                                    let commodityType = b.skuType;
                                    let commodityForBulk = _.find(commoditiesAvailable, c => c.skuType === b.skuType);
                                    if (!_.isNil(commodityForBulk) && !_.isNil(commodityForBulk.payloadInputName)) {
                                        commodityType = _.get(commodityForBulk, `payloadInputName.${lang}`, b.skuType);
                                    }
                                    const commodityIcon = _.get(commodityForBulk, 'iconName');

                                    let subPayloads = _(_.get(b, 'commoditiesProcessedBreakdown', []))
                                        .filter({ isSubCommodity: true })
                                        .map(entry => {
                                            return {
                                                amount: _.get(entry, 'totalAmount', 0),
                                                skuType: b.skuType,
                                                subCommodity: _.get(entry, 'subCommodity')
                                            };
                                        })
                                        .value();

                                    const combinedPayloadItems = _commodity.getCombinedPayloadItemsForPickups(
                                        [{ payload: {}, subPayloads }],
                                        commoditiesAvailable
                                    );
                                    const groupedPayloadTotals = _commodity.groupPayloadItemCountsByUnit(
                                        combinedPayloadItems
                                    );

                                    /*let counts = (
                        <TableRow style={{ height: 36 }}>
                            <TableCell
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    color: theme.palette.text.disabled
                                }}
                                colspan="2"
                            >
                                {isManual ? loc('dashboardHistory53', lang) : loc('dashboardHistory54', lang)}
                            </TableCell>
                        </TableRow>
                    );*/
                                    let countsCombined = [];
                                    let totalCounts = 0;
                                    if (b.dateCounted) {
                                        countsCombined = getCombinedCounts(b, rates, lang);
                                        totalCounts = countsCombined.reduce((sum, count) => sum + count.quantity, 0);
                                    }

                                    let driverTip = _.get(b, 'tip.adjustedAmount');
                                    if (!_.isNil(driverTip)) driverTip *= 100;

                                    return (
                                        <TableRow key={b._id} style={{ height: 20 }}>
                                            <TableCell style={tableCellStyles}>
                                                <Typography color="textSecondary">
                                                    <span style={{ marginRight: theme.spacing.unit }}>
                                                        <MDIcon
                                                            path={_.get(allIcons, commodityIcon)}
                                                            size={0.6}
                                                            color={theme.palette.text.secondary}
                                                        />
                                                    </span>
                                                    {uppercaseFirstLetter(commodityType)}
                                                </Typography>
                                            </TableCell>
                                            {!b.dateCounted && (
                                                <TableCell style={tableCellStyles}>
                                                    <Typography
                                                        style={{ ...tableCellStyles, textAlign: 'right' }}
                                                        color="textSecondary"
                                                    >
                                                        {loc('taxReceipts8', lang)}
                                                    </Typography>
                                                </TableCell>
                                            )}
                                            {b.dateCounted && (
                                                <TableCell
                                                    style={{ ...tableCellStyles, textAlign: 'right' }}
                                                    color="textSecondary"
                                                >
                                                    {_.round(totalCounts, 2)}{' '}
                                                    {_.get(b, 'skuType', '') === 'clothing'
                                                        ? 'lbs'
                                                        : loc('items', lang)}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </>
                )}
                {adminView && (
                    <>
                        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <div style={{ width: '100%' }}>
                                <Tooltip title="View Bulk">
                                    <div
                                        style={{
                                            height: 'auto',
                                            width: '100%',
                                            margin: theme.spacing.unit * 0.5,
                                            padding: theme.spacing.unit / 2,
                                            backgroundColor: '#e0e0e0',
                                            borderRadius: '16px',
                                            overflow: 'auto',
                                            cursor: 'pointer'
                                        }}
                                        onClick={e =>
                                            (_user.isSystemAdmin(auth) ||
                                                (_user.isInternalRole(auth) &&
                                                    (rolePermissions &&
                                                        rolePermissions.orders &&
                                                        rolePermissions.orders.counter))) &&
                                            handleViewBulk(e.ctrlKey, firstBulk)
                                        }
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <MDIcon
                                                path={_.get(allIcons, 'mdiAccountHardHat')}
                                                size={0.9}
                                                color={theme.palette.text.secondary}
                                                style={{ margin: theme.spacing.unit / 2 }}
                                            />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                                    {machineCounter
                                                        ? machineCounter
                                                        : !_.isNil(lastCounter)
                                                        ? getCustomerFirstNameAndLastInitial(lastCounter)
                                                        : 'TBD'}
                                                </Typography>
                                                {/* {!_.isNil(_.get(lastManualCountedBulk, 'dateCounted')) && (
                                                    <Typography style={{ fontSize: '11px', lineHeight: 1.2 }}>
                                                        {loc('processedOn', lang)}:{' '}
                                                        {locDate(
                                                            _.get(lastManualCountedBulk, 'dateCounted'),
                                                            'MMM Do h:mm a',
                                                            lang,
                                                            timezone
                                                        )}
                                                    </Typography>
                                                )} */}
                                                <Typography style={{ fontSize: '11px', lineHeight: 1.2 }}>
                                                    Collector Code: {_.get(firstBulk, 'collector.code', 'N/A')}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </Tooltip>
                                <Bagtags
                                    customer={customer}
                                    bagtags={allBagTags}
                                    sessions={sessions}
                                    collector={_.get(firstBulk, 'collector')}
                                    lang={lang}
                                    theme={theme}
                                />
                                {firstBulk.isLostAndFound && <Typography>{loc('dashboardHistory25', lang)}</Typography>}
                            </div>
                        </div>
                    </>
                )}
            </Paper>
        </>
    );
}

export default withTheme()(ProcessingSummary);

function getRateDescription(rate, lang) {
    const fallbackDescription = _.get(rate, 'description');
    const description = _.get(rate, `descriptions.${lang}`, fallbackDescription);

    return description;
}

function getCombinedCounts(bulk, rates, lang) {
    let countsCombined = [];
    // let conformants = {};
    let nonConformants = {};
    let conformantsByDescriptionAndValue = {};

    if (_.has(bulk, 'counts')) {
        bulk.counts.forEach(count => {
            if (_.has(count, 'items')) {
                count.items.forEach(item => {
                    const rateForSku = _.find(rates, { sku: item.sku });
                    const updatedItemObj = {
                        sku: item.sku,
                        size: item.size,
                        quantity: item.quantity,
                        materialType: item.materialType,
                        fullDescription: (
                            <span>
                                {getRateDescription(rateForSku, lang) || item.materialType}{' '}
                                {!_.isEmpty(item.size) ? (
                                    <span style={{ whiteSpace: 'nowrap' }}>({item.size})</span>
                                ) : null}
                            </span>
                        )
                    };
                    const description = getRateDescription(rateForSku, lang) || item.materialType;
                    const rateValue = _.get(rateForSku, 'value', 0) * -1;
                    let groupName = `${description}${rateValue}`.trim();
                    if (['NON', 'Non-conformant'].includes(item.materialType)) {
                        if (!_.isNil(nonConformants[item.sku])) {
                            updatedItemObj.quantity += nonConformants[item.sku].quantity;
                        }
                        nonConformants[item.sku] = updatedItemObj;
                    } else {
                        if (!_.isNil(conformantsByDescriptionAndValue[groupName])) {
                            updatedItemObj.quantity += conformantsByDescriptionAndValue[groupName].quantity;
                        }

                        conformantsByDescriptionAndValue[groupName] = updatedItemObj;

                        // if (!_.isNil(conformants[item.sku])) {
                        // updatedItemObj.quantity += conformants[item.sku].quantity;
                        // }
                        // conformants[item.sku] = updatedItemObj;
                    }
                });
            }
        });

        // const sortedCounts = _.sortBy(Object.values(conformants), ['size', 'materialType']);
        const sortedCounts = _.sortBy(Object.values(conformantsByDescriptionAndValue), ['size', 'materialType']);
        const nonConformantCounts = Object.values(nonConformants);

        countsCombined = _.concat(sortedCounts, nonConformantCounts);
    }
    return countsCombined;
}

function getCombinedCountsCountingSession(countingSession, rates, lang) {
    let countsCombined = [];
    // let conformants = {};
    let nonConformants = {};
    let conformantsByDescriptionAndValue = {};

    if (_.has(countingSession, 'items')) {
        _.get(countingSession, 'items', []).forEach(item => {
            const rateForSku = _.find(rates, { sku: item.sku });
            const updatedItemObj = {
                sku: item.sku,
                size: item.size,
                quantity: item.quantity,
                materialType: item.materialType,
                fullDescription: (
                    <span>
                        {getRateDescription(rateForSku, lang) || item.materialType}{' '}
                        {!_.isEmpty(item.size) ? <span style={{ whiteSpace: 'nowrap' }}>({item.size})</span> : null}
                    </span>
                )
            };
            const description = getRateDescription(rateForSku, lang) || item.materialType;
            const rateValue = _.get(rateForSku, 'value', 0) * -1;
            let groupName = `${description}${rateValue}`.trim();
            if (['NON', 'Non-conformant'].includes(item.materialType)) {
                if (!_.isNil(nonConformants[item.sku])) {
                    updatedItemObj.quantity += nonConformants[item.sku].quantity;
                }
                nonConformants[item.sku] = updatedItemObj;
            } else {
                if (!_.isNil(conformantsByDescriptionAndValue[groupName])) {
                    updatedItemObj.quantity += conformantsByDescriptionAndValue[groupName].quantity;
                }

                conformantsByDescriptionAndValue[groupName] = updatedItemObj;

                // if (!_.isNil(conformants[item.sku])) {
                // updatedItemObj.quantity += conformants[item.sku].quantity;
                // }
                // conformants[item.sku] = updatedItemObj;
            }
        });

        // const sortedCounts = _.sortBy(Object.values(conformants), ['size', 'materialType']);
        const sortedCounts = _.sortBy(Object.values(conformantsByDescriptionAndValue), ['size', 'materialType']);
        const nonConformantCounts = Object.values(nonConformants);

        countsCombined = _.concat(sortedCounts, nonConformantCounts);
    }
    return countsCombined;
}

function getGroupedCounts({ b, rates, commodityIcon, tableCellStyles, theme, lang }) {
    let totalCounts = 0,
        counts = <></>;

    let countsCombined = [];
    countsCombined = getCombinedCounts(b, rates, lang);
    totalCounts = countsCombined.reduce((sum, count) => sum + count.quantity, 0);
    let displayLine =
        bulkHelper.getCustomerAmount(b) !== 0 ||
        _.get(b, 'skuType', '') === 'beverage' ||
        _.get(b, 'skuType', '') === 'collectionbins' ||
        bulkHelper.isDonating(b);
    if (!_.isEmpty(countsCombined)) {
        counts = countsCombined.map(
            count =>
                displayLine && (
                    <TableRow key={count.sku} style={{ height: 20 }}>
                        <TableCell
                            style={{
                                ...tableCellStyles,
                                paddingTop: 0,
                                paddingBottom: 0,
                                color: ['NON', 'Non-conformant'].includes(count.materialType)
                                    ? colors.red[500]
                                    : theme.palette.text.secondary
                            }}
                        >
                            <span style={{ marginRight: theme.spacing.unit }}>
                                <MDIcon
                                    path={_.get(allIcons, commodityIcon)}
                                    size={0.6}
                                    color={theme.palette.text.secondary}
                                />
                            </span>
                            {count.fullDescription}
                        </TableCell>
                        <TableCell
                            style={{
                                ...tableCellStyles,
                                paddingTop: 0,
                                paddingBottom: 0,
                                color: ['NON', 'Non-conformant'].includes(count.materialType)
                                    ? colors.red[500]
                                    : theme.palette.text.secondary,
                                textAlign: 'right'
                            }}
                        >
                            {_.round(count.quantity, 2)}{' '}
                            {_.get(b, 'skuType', '') === 'clothing' ? 'lbs' : loc('items', lang)}
                        </TableCell>
                    </TableRow>
                )
        );
    } else {
        counts = <></>;
    }

    return { totalCounts, counts };
}

function getGroupedCountsByBagtag({ b, rates, commodityIcon, tableCellStyles, theme, lang }) {
    let totalCounts = 0,
        counts = <></>;

    let displayLine =
        bulkHelper.getCustomerAmount(b) !== 0 ||
        _.get(b, 'skuType', '') === 'beverage' ||
        _.get(b, 'skuType', '') === 'collectionbins' ||
        bulkHelper.isDonating(b);

    const countingSessions = _.get(b, 'countingSessions', []);
    if (!_.isEmpty(countingSessions)) {
        counts = countingSessions.map((countingSession, i) => {
            const countsCombined = getCombinedCountsCountingSession(countingSession, rates, lang);
            return (
                displayLine && (
                    <React.Fragment key={i}>
                        <TableRow style={{ height: 20 }}>
                            <TableCell
                                style={{
                                    ...tableCellStyles,
                                    paddingTop: 0,
                                    paddingBottom: 0
                                }}
                            >
                                <span style={{ marginRight: theme.spacing.unit }}>
                                    <MDIcon
                                        path={_.get(allIcons, 'mdiLabel')}
                                        size={0.6}
                                        color={theme.palette.text.secondary}
                                    />
                                </span>
                                {_.get(countingSession, 'bagtag', '')}
                            </TableCell>
                            <TableCell
                                style={{
                                    ...tableCellStyles,
                                    paddingTop: 0,
                                    paddingBottom: 0
                                }}
                            />
                        </TableRow>
                        {countsCombined.map(
                            count =>
                                displayLine && (
                                    <TableRow key={count.sku} style={{ height: 20 }}>
                                        <TableCell
                                            style={{
                                                ...tableCellStyles,
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                color: ['NON', 'Non-conformant'].includes(count.materialType)
                                                    ? colors.red[500]
                                                    : theme.palette.text.secondary
                                            }}
                                        >
                                            <span style={{ marginRight: theme.spacing.unit }}>
                                                <MDIcon
                                                    path={_.get(allIcons, commodityIcon)}
                                                    size={0.6}
                                                    color={theme.palette.text.secondary}
                                                />
                                            </span>
                                            {count.fullDescription}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                ...tableCellStyles,
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                color: ['NON', 'Non-conformant'].includes(count.materialType)
                                                    ? colors.red[500]
                                                    : theme.palette.text.secondary,
                                                textAlign: 'right'
                                            }}
                                        >
                                            {_.round(count.quantity, 2)}{' '}
                                            {_.get(b, 'skuType', '') === 'clothing' ? 'lbs' : loc('items', lang)}
                                        </TableCell>
                                    </TableRow>
                                )
                        )}
                    </React.Fragment>
                )
            );
        });
    } else {
        counts = <></>;
    }

    return { totalCounts, counts };
}
