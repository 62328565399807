import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Icon,
    IconButton,
    TableCell,
    TableRow,
    Typography,
    Button,
    Paper,
    Dialog,
    DialogContentText,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import { red, green, yellow } from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import useTable from 'components/Table/useTable';
import Table from 'components/Table/Table';
import FeatuerdCharityCard from './FeaturedCharityCard';
import { CharityInfoEditDialog, CharityTableEditDialog, CharityTaskEditDialog, FeatureCharityDialog } from './Dialogs';

import { deviceHelper, formatAsCurrency } from 'utils/misc';
import charityTaskHelper from 'helpers/charityTaskHelper';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import { downloadObjectAsCSV } from 'utils/misc';
import { ROLES } from '../../../constants';
import CharityManageAccessDialog from './Dialogs/CharityManageAccessDialog';

const headers = [
    { name: 'Actions', locKey: null },
    { name: 'Organization^', locKey: null },
    { name: 'Website', locKey: null },
    { name: 'Closest City^', locKey: null },
    { name: 'Date Registered^', locKey: null },
    { name: 'Close Date^', locKey: null },
    { name: 'Charity Registration Number^', locKey: null },
    { name: 'Tax^', locKey: null },
    { name: 'Growth Tasks^', locKey: null } //Caret used to denote this header can be sorted by
];

const Charities = ({ history, http, operator, theme, setRefreshPendingCharityCount, rolePermissions }) => {
    const [loading, setLoading] = useState(false);
    const [regions, setRegions] = useState([]);
    const [tableEditDialog, setTableEditDialog] = useState(null);
    const [infoEditDialog, setInfoEditDialog] = useState(null);
    const [taskEditDialog, setTaskEditDialog] = useState(null);
    const [featureEditDialog, setFeatureEditDialog] = useState(null);
    const [rejectWarningDialog, setRejectWarningDialog] = useState(false);
    const [rejectWarningText, setRejectWarningText] = useState('');
    const [manageAccessDialog, setManageAccessDialog] = useState(false);
    const [charityManagers, setCharityManagers] = useState([]);
    const [featuredCharities, setFeaturedCharities] = useState({});
    const [infoEdits, setInfoEdits] = useState([]);
    const [view, setView] = useState('all');
    const [charityTaskConfig, setCharityTaskConfig] = useState(null);

    const [selectedCharityID, setSelectedCharityID] = useState(null);

    const onSnackbar = useContext(SnackbarContext);
    const { lang } = useContext(LocalizationContext);

    const {
        getData: refreshApproved,
        loading: aLoading,
        page: aPage,
        rowsPerPage: aRowsPerPage,
        filteredData: aFilteredData,
        search: aSearch,
        filteredHeaders: aFilteredHeaders,
        totalDataEntries: aTotalDataEntries,
        handleChange: aHandleChange,
        handleSearch: aHandleSearch,
        handleChangeFilterHeaders: aHandleChangeFilterHeaders,
        handleAllFilterHeaders: aHandleAllFilterHeaders,
        handleChangePage: aHandleChangePage,
        handleChangeRowsPerPage: aHandleChangeRowsPerPage
    } = useTable({
        url: '/charities?type=approved',
        searchTermKey: 'a',
        key: 'charities',
        headers: headers.map(({ name }) => name)
    });

    const {
        getData: refreshPending,
        loading: pLoading,
        page: pPage,
        rowsPerPage: pRowsPerPage,
        filteredData: pFilteredData,
        search: pSearch,
        filteredHeaders: pFilteredHeaders,
        totalDataEntries: pTotalDataEntries,
        handleChange: pHandleChange,
        handleSearch: pHandleSearch,
        handleChangeFilterHeaders: pHandleChangeFilterHeaders,
        handleAllFilterHeaders: pHandleAllFilterHeaders,
        handleChangePage: pHandleChangePage,
        handleChangeRowsPerPage: pHandleChangeRowsPerPage
    } = useTable({
        url: '/charities?type=pending',
        searchTermKey: 'p',
        key: 'charities',
        headers: headers.map(({ name }) => name)
    });

    const {
        getData: refreshRejected,
        loading: rLoading,
        page: rPage,
        rowsPerPage: rRowsPerPage,
        filteredData: rFilteredData,
        search: rSearch,
        filteredHeaders: rFilteredHeaders,
        totalDataEntries: rTotalDataEntries,
        handleChange: rHandleChange,
        handleSearch: rHandleSearch,
        handleChangeFilterHeaders: rHandleChangeFilterHeaders,
        handleAllFilterHeaders: rHandleAllFilterHeaders,
        handleChangePage: rHandleChangePage,
        handleChangeRowsPerPage: rHandleChangeRowsPerPage
    } = useTable({
        url: '/charities?type=rejected',
        searchTermKey: 'r',
        key: 'charities',
        headers: headers.map(({ name }) => name)
    });

    const reloadTables = async () => {
        await refreshApproved();
        await refreshPending();
        await refreshRejected();
    };

    const reloadInfoEdits = async () => {
        const infoEditsRes = await http.getJSON('/charities/infoEdits');
        if (infoEditsRes.ok) {
            setInfoEdits(infoEditsRes.data.infoEdits);
        }
    };

    const reloadFeaturedCharities = async () => {
        const featuredCharitiesRes = await http.getJSON('/charities/featured');
        if (featuredCharitiesRes.ok) {
            const regionsObj = {};
            const { charities } = featuredCharitiesRes.data;
            charities.forEach(charity => {
                charity.regionsFeaturedIn.forEach(region => {
                    const regionName = _.get(region, 'name', 'No Featured Region Specified');
                    const inRegionsObj = Object.keys(regionsObj).includes(regionName);

                    if (inRegionsObj) {
                        regionsObj[regionName].push(charity);
                    } else {
                        regionsObj[regionName] = [charity];
                    }
                });
            });
            setFeaturedCharities(regionsObj);
        }
    };

    async function loadCharityManagers() {
        const charityManagersRes = await http.getJSON('/charities/managers');
        if (charityManagersRes.ok) {
            setCharityManagers(charityManagersRes.data.charityManagers);
        }
    }

    useEffect(() => {
        async function loadRegions() {
            const regionsRes = await http.getJSON('/regions');
            if (regionsRes.ok) {
                setRegions(regionsRes.data.regions);
            }
        }

        async function loadCharityTaskConfig() {
            const charityTaskConfigRes = await http.getJSON('/config/charityTaskConfiguration');
            if (charityTaskConfigRes.ok) {
                setCharityTaskConfig(_.get(charityTaskConfigRes, 'data.config'));
            }
        }

        (async function loadInitialData() {
            await loadRegions();
            await loadCharityManagers();
            await loadCharityTaskConfig();
        })();

        reloadInfoEdits();
        reloadFeaturedCharities();
    }, []);

    const handleChangeView = (e, name) => {
        setView(name);
    };

    const handleView = (_id, charity_id) => () => {
        if (
            ROLES.includes(operator.accountType) &&
            rolePermissions &&
            !_.get(rolePermissions, 'accounts.charity', false) &&
            _.get(rolePermissions, 'accounts.customer', false)
        ) {
            window.open(`/customers/${_id}/`, '_blank');
            return;
        }
        if (!deviceHelper.isNativeApp()) {
            window.open(`/customers/${_id}/${charity_id}/charity`, '_blank');
        } else {
            history.push(`/customers/${_id}/${charity_id}/charity`);
        }
    };

    const handleDialog = (name, charity = null) => () => {
        if (name === 'tableEditDialog') {
            setTableEditDialog(charity);
        } else if (name === 'infoEditDialog') {
            setInfoEditDialog(charity);
        } else if (name === 'taskEditDialog') {
            setTaskEditDialog(charity);
        } else if (name === 'featureEditDialog') {
            setFeatureEditDialog(charity);
        }
    };

    const handleOpenWebsite = url => () => {
        if (!_.isNil(window.cordova)) {
            window.open(url, '_system');
        } else {
            window.open(url);
        }
    };

    const handleUpdate = async (taxReceiptsIssued, form, file, differentTeams, overrideMarkerUrl, markerFile) => {
        setLoading(true);
        let formAdjusted = _.cloneDeep(form);
        formAdjusted.url = formAdjusted.url.toLowerCase();
        formAdjusted.taxReceiptsIssued = taxReceiptsIssued;
        formAdjusted.location.lat = formAdjusted.lat;
        formAdjusted.location.lng = formAdjusted.lng;
        formAdjusted.overrideMarkerUrl = overrideMarkerUrl;

        delete formAdjusted.lng;
        delete formAdjusted.lat;
        delete formAdjusted.charityTasks;

        if (!formAdjusted.taxReceiptsIssued) {
            formAdjusted.charityRegistrationNumber = undefined;
        }

        if (!differentTeams) {
            formAdjusted.subdivisions = [];
        }

        const formData = new FormData(); // NB: FormData objects cannot be displayed in console.log
        formData.append('logo', file);
        if (overrideMarkerUrl) {
            formData.append('markerFile', markerFile);
        }
        formData.append('form', JSON.stringify(formAdjusted)); // will show up in req.body

        const res = await http.postJSON(
            `/charities/${form._id}/${form.approved ? 'update' : 'approve'}`,
            formData,
            true,
            true
        );

        if (res.ok) {
            setTableEditDialog(null);

            await reloadTables();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleApproveEdits = async id => {
        setLoading(true);
        let res = await http.postJSON(`/charities/${id}/approve`);

        if (res.ok) {
            setInfoEditDialog(null);

            await reloadInfoEdits();
            await reloadTables();
        }
        setLoading(false);
    };

    const handleReject = async (id, bypassWarning = false) => {
        setLoading(true);
        if (!bypassWarning) {
            let charityBalanceRes = await http.getJSON(`/charities/${id}/unredeemedDonations`);
            if (charityBalanceRes.ok) {
                let totalValue = charityBalanceRes.data.totalValue;
                if (totalValue > 0) {
                    setRejectWarningText(
                        `This organization has ${formatAsCurrency(
                            totalValue
                        )} of unredeemed donations that will be refunded if this organization is rejected. Are you sure you want to continue?`
                    );
                    setRejectWarningDialog(true);
                    setLoading(false);
                    return;
                }
            }
        }
        let reason = '';
        let res = await http.postJSON(`/charities/${id}/reject`, { reason });

        if (res.ok) {
            setInfoEditDialog(null);
            setTableEditDialog(null);
            setRejectWarningText('');
            setRejectWarningDialog(false);

            await reloadInfoEdits();
            await reloadTables();
        }
        setLoading(false);
    };

    const handleRejectTask = async (charityTask, rejectionReason) => {
        setLoading(true);
        const res = await http.postJSON(`/charities/${taskEditDialog._id}/rejectCharityTask`, {
            taskId: charityTask.task._id,
            rejectionReason
        });
        if (res.ok) {
            setTaskEditDialog(res.data.charity);

            await refreshApproved();
            await refreshPending();
            setRefreshPendingCharityCount(true);
        }
        setLoading(false);
    };

    const handleVerifyTask = async charityTask => {
        setLoading(true);
        const res = await http.postJSON(`/charities/${taskEditDialog._id}/verifyCharityTask`, {
            taskId: charityTask.task._id
        });

        if (res.ok) {
            setTaskEditDialog(res.data.charity);

            await refreshApproved();
            await refreshPending();
            setRefreshPendingCharityCount(true);
        }
        setLoading(false);
    };

    const handleUndoApproveTaskWithoutSubmission = async charityTask => {
        setLoading(true);
        const res = await http.postJSON(`/charities/${taskEditDialog._id}/undoApproveTaskWithoutSubmission`, {
            taskId: charityTask.task._id
        });
        if (res.ok) {
            setTaskEditDialog(res.data.charity);

            await refreshApproved();
            await refreshPending();
            setRefreshPendingCharityCount(true);
        }
        setLoading(false);
    };

    const handleFeatureEditCities = async (regionsFeaturedIn, disableAutoFeatureAndUnfeature) => {
        setLoading(true);
        const res = await http.postJSON(
            `/charities/${featureEditDialog._id}/setFeaturedCities`,
            { regionsFeaturedIn, disableAutoFeatureAndUnfeature },
            true
        );
        if (res.ok) {
            setFeatureEditDialog(null);

            await refreshApproved();
        }
        setLoading(false);
    };

    const handleFeatureApprove = async (regionsFeaturedIn, disableAutoFeatureAndUnFeature) => {
        setLoading(true);
        const res = await http.postJSON(
            `/charities/${featureEditDialog._id}/feature`,
            {
                regionsFeaturedIn,
                disableAutoFeatureAndUnFeature,
                tempFeature: !_.get(featureEditDialog, 'isFeatured', false)
            },
            true
        );
        if (res.ok) {
            setFeatureEditDialog(null);

            await refreshApproved();
        }
        setLoading(false);
    };

    const handleFeatureRemove = async disableAutoFeatureAndUnfeature => {
        setLoading(true);
        const res = await http.postJSON(
            `/charities/${featureEditDialog._id}/unfeature`,
            { disableAutoFeatureAndUnfeature },
            true
        );
        if (res.ok) {
            setFeatureEditDialog(null);

            await refreshApproved();
        }
        setLoading(false);
    };

    const handleManageAccessDialog = async charity_id => {
        setSelectedCharityID(charity_id);
        setManageAccessDialog(true);
    };

    const handleDownloadCharityInfo = async () => {
        setLoading(true);
        const res = await http.getJSON(`/charities/getCharityReport`);
        if (!res.ok) {
            onSnackbar('Unable to download report', 'error');
        }
        const date = moment().format('YYYY-MM-DD HH:mm');
        const fileName = `charity_report_${date}`;
        try {
            if (_.isEmpty(res.data.charityData)) {
                onSnackbar('No information available', 'info');
            } else {
                await downloadObjectAsCSV(res.data.charityData, fileName);
            }
        } catch (err) {
            console.log(err);
        }

        setLoading(false);
    };

    const cellStyle = { whiteSpace: 'nowrap' };

    const renderRow = (org, filteredHeaders) => {
        const {
            _id,
            approved,
            name,
            website,
            location,
            taxReceiptsIssued,
            redemptionEmail,
            isFeatured,
            approvalPending,
            charityTasks,
            requiresReview,
            closed,
            hasCloseDate,
            closeDate,
            charityRegistrationNumber
        } = org;

        const charityTasksNeedVerification = _.some(
            charityTasks,
            charityTask => charityTask.lastSubmission && !charityTask.lastSubmission.reviewed
        );
        const numCharityTasksComplete = charityTaskHelper.numberOfTasksComplete(charityTasks);
        const numCharityTasksCompletable = _.filter(
            charityTasks,
            charityTask => !charityTask.task.notCompletable && !charityTask.task.hidden
        ).length;

        const charityManager = _.first(
            _.filter(charityManagers, manager => _.get(manager, 'charities', []).includes(_id))
        );

        const tableRowsLoading = aLoading || pLoading || rLoading;

        return (
            <TableRow key={_id}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell style={cellStyle}>
                        <IconButton
                            data-cy={`charity-table-edit-button-${redemptionEmail}`}
                            name="approved"
                            style={{
                                color: closed
                                    ? 'auto'
                                    : approved
                                    ? green[500]
                                    : approvalPending
                                    ? yellow[800]
                                    : red[500]
                            }}
                            onClick={handleDialog('tableEditDialog', org)}
                            disabled={tableRowsLoading}
                        >
                            <Icon>verified_user</Icon>
                        </IconButton>
                        <IconButton
                            onClick={handleDialog('infoEditDialog', org)}
                            style={{ color: requiresReview ? yellow[800] : null }}
                            disabled={tableRowsLoading}
                        >
                            <Icon>history</Icon>
                        </IconButton>
                        <IconButton
                            data-cy={`charity-table-featured-button-${redemptionEmail}`}
                            style={{ color: isFeatured ? yellow['600'] : null }}
                            onClick={handleDialog('featureEditDialog', org)}
                            disabled={tableRowsLoading || closed}
                        >
                            <Icon>grade</Icon>
                        </IconButton>
                        <IconButton
                            disabled={_.isNil(charityManager)}
                            onClick={handleView(_.get(charityManager, '_id'), _id)}
                        >
                            <Icon>visibility</Icon>
                        </IconButton>
                        <IconButton onClick={() => handleManageAccessDialog(_id)}>
                            <Icon>people_alt</Icon>
                        </IconButton>
                    </TableCell>
                )}

                {filteredHeaders.includes('Organization^') && (
                    <TableCell style={cellStyle}>
                        {name}
                        {closed
                            ? ' (Closed)'
                            : hasCloseDate && moment(closeDate).isBefore(moment())
                            ? ' (Closing Soon)'
                            : ''}
                    </TableCell>
                )}

                {filteredHeaders.includes('Website') && (
                    <TableCell style={cellStyle}>
                        <IconButton onClick={handleOpenWebsite(website)}>
                            <Icon>public</Icon>
                        </IconButton>
                    </TableCell>
                )}

                {filteredHeaders.includes('Closest City^') && (
                    <TableCell data-cy={`charity-table-${redemptionEmail}-closest-city`} style={cellStyle}>
                        {_.get(location, 'closestRegion', '')}
                    </TableCell>
                )}

                {filteredHeaders.includes('Date Registered^') && (
                    <TableCell style={cellStyle}>
                        {moment(parseInt(_id.toString().substring(0, 8), 16) * 1000).format('D MMM YYYY')}
                    </TableCell>
                )}

                {filteredHeaders.includes('Close Date^') && (
                    <TableCell style={cellStyle}>
                        {hasCloseDate
                            ? moment(closeDate)
                                  .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                  .format('D MMM YYYY')
                            : 'None'}
                    </TableCell>
                )}

                {filteredHeaders.includes('Charity Registration Number^') && (
                    <TableCell style={cellStyle}>
                        {_.isNil(charityRegistrationNumber) || _.isEmpty(charityRegistrationNumber)
                            ? 'N/A'
                            : charityRegistrationNumber}
                    </TableCell>
                )}

                {filteredHeaders.includes('Tax^') && (
                    <TableCell style={cellStyle}>
                        <Icon style={{ color: taxReceiptsIssued ? green[500] : red[500] }}>
                            {taxReceiptsIssued ? 'check' : 'close'}
                        </Icon>
                    </TableCell>
                )}

                {(approved || approvalPending) && filteredHeaders.includes('Growth Tasks^') && (
                    <TableCell style={cellStyle}>
                        <Button
                            data-cy={`charity-table-charity-task-button-${redemptionEmail}`}
                            onClick={handleDialog('taskEditDialog', org)}
                            variant="outlined"
                            color={charityTasksNeedVerification ? null : 'secondary'}
                            size="small"
                        >
                            {numCharityTasksComplete}/{numCharityTasksCompletable}
                        </Button>
                    </TableCell>
                )}
            </TableRow>
        );
    };

    const downloadButton = (
        <Button
            disabled={aLoading || pLoading || rLoading || loading}
            variant="contained"
            onClick={handleDownloadCharityInfo}
            style={{ marginRight: theme.spacing.unit / 2 }}
        >
            <Icon>download</Icon>
        </Button>
    );

    return (
        <>
            <div style={{ padding: theme.spacing.unit * 2, display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup value={view} exclusive onChange={handleChangeView}>
                    <ToggleButton value="all">All</ToggleButton>
                    <ToggleButton value="featured">Featured</ToggleButton>
                </ToggleButtonGroup>
            </div>
            {view === 'featured' && (
                <div style={{ padding: theme.spacing.unit * 2 }}>
                    <Paper>
                        <Typography variant="h6" style={{ padding: theme.spacing.unit * 2 }}>
                            Featured Charities
                        </Typography>

                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                            {Object.keys(featuredCharities).map(regionName => {
                                const charities = featuredCharities[regionName];

                                return (
                                    <FeatuerdCharityCard
                                        charities={charities}
                                        regionName={regionName}
                                        handleDialog={handleDialog}
                                        key={regionName}
                                    />
                                );
                            })}
                        </div>
                    </Paper>
                </div>
            )}

            {view === 'all' && (
                <>
                    <div>
                        <Table
                            title="Pending Approval"
                            loading={pLoading}
                            headers={headers}
                            filteredHeaders={pFilteredHeaders}
                            length={pTotalDataEntries}
                            page={pPage}
                            rowsPerPage={pRowsPerPage}
                            search={pSearch}
                            handleChange={pHandleChange}
                            handleChangeFilterHeaders={pHandleChangeFilterHeaders}
                            handleAllFilterHeaders={pHandleAllFilterHeaders}
                            handleSearch={pHandleSearch}
                            handleChangePage={pHandleChangePage}
                            handleChangeRowsPerPage={pHandleChangeRowsPerPage}
                            refresh={refreshPending}
                            customAction={downloadButton}
                        >
                            {pFilteredData &&
                                pFilteredData.map(org => {
                                    return renderRow(org, pFilteredHeaders);
                                })}
                        </Table>
                    </div>

                    <div>
                        <Table
                            title={loc('growthGeneral2', lang)}
                            loading={aLoading}
                            headers={headers}
                            filteredHeaders={aFilteredHeaders}
                            length={aTotalDataEntries}
                            page={aPage}
                            rowsPerPage={aRowsPerPage}
                            search={aSearch}
                            handleChange={aHandleChange}
                            handleChangeFilterHeaders={aHandleChangeFilterHeaders}
                            handleAllFilterHeaders={aHandleAllFilterHeaders}
                            handleSearch={aHandleSearch}
                            handleChangePage={aHandleChangePage}
                            handleChangeRowsPerPage={aHandleChangeRowsPerPage}
                            refresh={refreshApproved}
                            customAction={downloadButton}
                        >
                            {aFilteredData &&
                                aFilteredData.map(org => {
                                    return renderRow(org, aFilteredHeaders);
                                })}
                        </Table>
                    </div>

                    <div>
                        <Table
                            title={loc('growthGeneral3', lang)}
                            loading={rLoading}
                            headers={headers.slice(0, 8)}
                            filteredHeaders={rFilteredHeaders}
                            length={rTotalDataEntries}
                            page={rPage}
                            rowsPerPage={rRowsPerPage}
                            search={rSearch}
                            handleChange={rHandleChange}
                            handleChangeFilterHeaders={rHandleChangeFilterHeaders}
                            handleAllFilterHeaders={rHandleAllFilterHeaders}
                            handleSearch={rHandleSearch}
                            handleChangePage={rHandleChangePage}
                            handleChangeRowsPerPage={rHandleChangeRowsPerPage}
                            refresh={refreshRejected}
                            customAction={downloadButton}
                        >
                            {rFilteredData &&
                                rFilteredData.map(org => {
                                    return renderRow(org, rFilteredHeaders);
                                })}
                        </Table>
                    </div>
                </>
            )}
            {tableEditDialog && (
                <CharityTableEditDialog
                    dialogOpen={Boolean(tableEditDialog)}
                    handleReject={handleReject}
                    handleDialogClose={handleDialog('tableEditDialog')}
                    handleUpdate={handleUpdate}
                    charity={tableEditDialog}
                    loading={loading}
                    charityManagers={charityManagers}
                />
            )}
            {infoEditDialog && (
                <CharityInfoEditDialog
                    open={Boolean(infoEditDialog)}
                    organizationSelected={infoEditDialog}
                    disabledColor={theme.palette.text.disabled}
                    initialInfoEditsState={infoEdits}
                    onClose={handleDialog('infoEditDialog')}
                    onApproveEdits={handleApproveEdits}
                    onReject={handleReject}
                    loading={loading}
                />
            )}

            {taskEditDialog && (
                <CharityTaskEditDialog
                    open={Boolean(taskEditDialog)}
                    onClose={handleDialog('taskEditDialog')}
                    charity={taskEditDialog}
                    handleRejectTask={handleRejectTask}
                    handleVerifyTask={handleVerifyTask}
                    loading={loading}
                    handleUndoApproveTaskWithoutSubmission={handleUndoApproveTaskWithoutSubmission}
                    charityTaskConfig={charityTaskConfig}
                    operator={operator}
                    history={history}
                />
            )}

            {featureEditDialog && (
                <FeatureCharityDialog
                    organizationSelected={featureEditDialog}
                    regions={regions}
                    featureDialogOpen={Boolean(featureEditDialog)}
                    handleFeatureDialogClose={handleDialog('featureEditDialog')}
                    handleFeatureApprove={handleFeatureApprove}
                    handleFeatureRemove={handleFeatureRemove}
                    handleFeatureEditCities={handleFeatureEditCities}
                    http={http}
                    loading={loading}
                />
            )}

            {manageAccessDialog && (
                <CharityManageAccessDialog
                    open={Boolean(manageAccessDialog)}
                    theme={theme}
                    http={http}
                    selectedCharityID={selectedCharityID}
                    charityManagers={_.filter(charityManagers, manager =>
                        _.get(manager, 'charities', []).includes(selectedCharityID)
                    )}
                    setManageAccessDialog={setManageAccessDialog}
                    handleView={handleView}
                    allowViewUser
                    reloadMethod={loadCharityManagers}
                />
            )}

            {rejectWarningDialog && (
                <Dialog
                    open={rejectWarningDialog}
                    onClose={() => {
                        setRejectWarningDialog(false);
                    }}
                >
                    <DialogContent>
                        <DialogContentText>{rejectWarningText}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setRejectWarningDialog(false)}>{loc('close', lang)}</Button>
                        <Button
                            onClick={() => {
                                handleReject(_.get(tableEditDialog, '_id', _.get(infoEditDialog, '_id')), true);
                            }}
                            color="secondary"
                        >
                            Reject
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default withTheme()(Charities);
